import { Show, splitProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { IconProps } from '../props';

export function IconUsers(props: IconProps) {
	const [, svgProps] = splitProps(props, ['title']);

	return (
		<svg
			viewBox="0 0 24 24"
			height="1.2em"
			width="1.2em"
			fill="none"
			stroke="none"
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<Show when={props.title}>
				<title>{props.title}</title>
			</Show>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M21 20c0-1.742-1.67-3.223-4-3.773M15 20c0-2.21-2.686-4-6-4s-6 1.79-6 4m12-7a4 4 0 0 0 0-8m-6 8a4 4 0 1 1 0-8a4 4 0 0 1 0 8"
			/>
		</svg>
	);
}
